/* Variables */
$normal-font-size: 0.875rem;
$big-font-size: 1rem;
$bigger-font-size: 1.25rem;
$biggest-font-size: 1.5rem;

$border-radius: 0.25rem;

// $font-family: 'Poppins', sans-serif;
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$first-color: #00c58a;
$bg-color: #121212;
$box-bg-color: #2b293475;
$white: white;
$label-color: #71727c;
$body-color: #888991;
$body-color-dark: #a4a5aa;
$input-color: #cfcfd6;
$placeholder-color: #414243;
$error-text-color: #ef4848;
$border-color: #585760;
$border-dark-color: #7c7b85;
$error-border-color: #f85252;
$radio-button-color: #9897a2;
$help-text-color: #626264;
$primary-btn-border-color: #00cc8f;
$danger-btn-border-color: #ef4848;
$disabled-button-bg-color: #2b2934b4;
$green: #4bb543;
$blue: #20aaee;
$orange: darkorange;
$error: #ff3030;
$light-green: #00cc8f;
$table-color: #c1c1ca;
$table-header-bg: #23222c;
$table-row-bg: #32303c;
$table-alt-row-bg: #46454f;

/* Generic styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $normal-font-size;
  background-color: $bg-color;
  color: $body-color;
}

.app {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

input:not([type='radio']),
select {
  color: $input-color;
  width: 100%;
  height: 1.75rem;
  padding: 0 0.25rem;
  font-size: $big-font-size;
  border: none;
  border-bottom: 1px solid $border-color;
  background-color: transparent;
  font-family: $font-family;
  transition: border 0.2s;
}

input:not([type='radio']).error {
  border-color: $error-border-color;
}

input:not([type='radio']):hover,
select:hover {
  border-color: $border-dark-color;
}

input:not([type='radio']):focus,
select:focus {
  border-color: $first-color;
}

input[type='radio'] {
  appearance: none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  border: 2px solid $border-dark-color;
  transition: 0.1s border ease-in;
  position: relative;
}

input[type='radio']:checked {
  border: 4px solid $first-color;
}

input[type='radio'] + label {
  font-size: calc(1rem - 1px);
}

input[type='radio']:hover,
input[type='radio']:focus {
  outline: 1px solid $light-green;
}

select {
  padding: 0;
  font-size: calc(1rem - 1px);
}

input::placeholder {
  color: $placeholder-color;
}

a {
  text-decoration: none;
  color: unset;
}

.blue {
  color: $blue !important;
}

.orange {
  color: $orange !important;
}

.green {
  color: $green !important;
}

.white {
  color: $white !important;
}

.error,
.red {
  color: $error !important;
}

.input-color {
  color: $input-color !important;
}

.title {
  font-size: $big-font-size;
  color: $input-color;
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1rem;
}

.input-group {
  width: 100%;
}

.input-group > label {
  display: block;
  margin-bottom: 0.25rem;
  color: $label-color;
}

.input-sub-group {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.input-wrapper[data-prefix]::before {
  position: absolute;
  content: attr(data-prefix);
  color: $label-color;
  left: 0;
  top: 50%;
  transform: translateY(-51%);
  padding-left: 0.125rem;
}

.input-wrapper[data-suffix]::after {
  position: absolute;
  content: attr(data-suffix);
  color: $label-color;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 0.125rem;
}

.input-wrapper[data-prefix] > input {
  padding-left: 1rem;
}

.input-wrapper[data-suffix] > input {
  padding-right: 4rem;
}

.radio-group label {
  display: flex;
  align-items: center;
  padding-left: 0.375rem;
  height: 1.75rem;
  font-size: $big-font-size;
  color: $input-color;
}

.radio-group {
  display: flex;
  gap: 0 1rem;
  align-items: center;
}

.radio-sub-group {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1rem 1rem;
  gap: 1rem;
}

button[type='submit'] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 100%;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  font-size: $big-font-size;
  cursor: pointer;
  color: white;
  background-color: $first-color;
  font-weight: 600;
  transition: all 0.2s;
}

button[type='submit']:hover,
button[type='submit']:focus {
  box-shadow: #74757663 0px 2px 5px;
}

button[type='submit']:disabled {
  color: $label-color;
  background-color: $disabled-button-bg-color;
}

button[type='submit']:disabled:hover,
button[type='submit']:disabled:focus {
  box-shadow: #88878763 0px 2px 5px;
}

label.required {
  position: relative;
}

label.required::after {
  position: absolute;
  content: '*';
  color: $error-text-color;
  margin-left: 0.125rem;
  margin-top: -0.125rem;
}

.btn-sm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0 1rem;
  width: max-content;
  border: 1px solid $primary-btn-border-color;
  border-radius: $border-radius;
  cursor: pointer;
  background-color: transparent;
  color: $primary-btn-border-color;
  transition: all 0.2s;
}

.btn-sm:hover,
.btn-sm:focus {
  color: $white;
  background-color: $primary-btn-border-color;
}

.btn-sm.danger {
  transition: all 0.2s;
  color: $danger-btn-border-color;
  border-color: $danger-btn-border-color;
}

.btn-sm.danger:hover,
.btn-sm.danger:focus {
  color: $white;
  background-color: $danger-btn-border-color;
}

.currency-symbol {
  opacity: 0.8;
  margin-right: 1px;
  font-weight: normal !important;
}

.relative-value {
  margin-left: 1px;
  opacity: 0.85;
}

.goal {
  color: $first-color;
  margin: 2rem 1rem -1.125rem 1rem;
  font-family: 'Kaushan Script', cursive;
  font-size: calc($bigger-font-size + 1px);
}

.summary-table .relative-value {
  font-size: 1.125em !important;
}

.navbar {
  min-height: 3rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  color: $first-color;
  font-size: $biggest-font-size;
  font-family: 'Kaushan Script', cursive;
}

.menu a,
.menu a i {
  color: $label-color;
  font-size: $bigger-font-size;
}

.menu a:hover i,
.menu a:focus i,
.menu a.active i {
  color: $input-color;
}

.card {
  margin: 0 1rem 1rem 1rem;
  border-radius: $border-radius;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: $box-bg-color;
}

.btn-group .bx {
  margin-top: 2px;
}

.breakdown-table {
  display: flex;
  overflow-x: auto;
  margin-top: -0.5rem;

  table {
    width: 100%;
    border-collapse: collapse;
    background-color: $table-row-bg;

    thead {
      td {
        color: $table-color;
        padding: 0.875rem 0.75rem;
        text-align: center;
        font-weight: 500;
        background-color: $table-header-bg;
      }
    }

    tbody {
      td:first-child {
        text-align: center;
      }

      tr {
        td {
          padding: 0.5rem 1rem;
          text-align: right;
          color: $table-color;
        }
      }

      tr:nth-child(even) {
        background-color: $table-alt-row-bg;
      }

      tr:last-child td {
        font-weight: 500;
        padding: 0.625rem 1rem;
        // background-color: $table-header-bg;
      }
    }
  }
}

.summary-table {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  gap: 1rem;
  margin-top: 2rem;
  cursor: pointer;

  .result-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .result {
      color: $body-color-dark;

      div {
        margin-bottom: 0.25rem;
        color: $label-color;
      }

      span {
        font-size: $bigger-font-size;
        font-weight: 500;

        small {
          font-weight: normal;
          opacity: 0.85;
        }
      }

      div {
        small {
          font-size: 75%;
          display: block;
          color: $input-color;
          opacity: 0.75;
        }
      }
    }
  }
}

.footer {
  min-height: 3rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: $label-color;

  a {
    padding: 0.375rem 0.75rem;
  }

  a.pfwiki:hover {
    color: $first-color;
  }
}

.settings {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  & > .title {
    padding: 1rem;
    padding-bottom: 0;
  }

  .form {
    gap: 1rem;
  }
}

@media all and (max-width: 600px) {
  .radio-group {
    flex-wrap: wrap;
  }

  .radio-sub-group {
    width: calc(50% - 0.5rem) !important;
  }
}

@media all and (min-width: 600px) {
  .app {
    max-width: 620px;
  }

  /* scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

/* Slider */

.switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $placeholder-color;
  -webkit-transition: 0.2s;
  transition: 0.1s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 2.5px;
  background-color: $white;
  -webkit-transition: 0.2s;
  transition: 0.1s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $green;
}

input:checked + .slider:before {
  transform: translateX(22px);
}
